<template>
  <b-modal
    :id="id"
    size="md"
    title="Từ chối duyệt cấp phép"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleReject"
    @hidden="resetState"
  >
    <validation-observer ref="rule">
      <b-form-group
        class="mt-1"
        label-for="classfication"
      >
        <label for="user">Nguyên nhân từ chối <span class="text-danger">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required"
          :custom-messages="customCode"
        >
          <b-form-textarea
            id="InputHelp"
            v-model="dataReject.reasonReject"
            rows="3"
            no-resize
            :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BFormGroup,
  BModal,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      dataReject: {
        reasonReject: '',
      },
      customCode: {
        required: 'Nguyên nhân từ chối là bắt buộc',
      },
    }
  },
  methods: {
    handleReject(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleReject', this.dataReject)
        }
      })
    },
    resetState() {
      this.dataReject = {
        reasonReject: '',
        numberConfirm: '',
      }
    },
  },
}
</script>
